export const New = () => {
  return (
    <div className="new">
      <p className="new__title">Quais são as novidades?</p>
      <p className="new__description">Seção de bônus e promoções atualizada</p>
      <p className="new__description">
        Adicionada a localização da agência, caixa eletrônico ou terminal mais
        próximo no mapa.
      </p>
      <p className="new__description">
        Adicionado suporte para Face ID e Touch ID.
      </p>
      <p className="new__description">
        Corrigido um problema com notificações push que ocorria para alguns
        usuários do Android 10.
      </p>
      <p className="new__description">
        Corrigimos um problema em que o texto nas Configurações era muito
        pequeno para usuários do Android.
      </p>
    </div>
  );
};
