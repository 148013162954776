import { useEffect } from "react";

export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let sub_id_4 = param.get("sub_id_4");

  let id = param.get("id");
  let feed_id = param.get("feed_id");
  let pixel = param.get("pixel");
  let fbclid = param.get("fbclid");
  let access_token = param.get("access_token");

  if (!pixel) {
    pixel = localStorage.getItem("pixel");
  }

  if (!fbclid) {
    fbclid = localStorage.getItem("fbclid");
  }

  if (!access_token) {
    access_token = localStorage.getItem("access_token");
  }

  if (!id) {
    id = localStorage.getItem("id");
  }

  if (!feed_id) {
    feed_id = localStorage.getItem("feed_id");
  }

  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }

  if (!sub_id_2) {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }

  if (!sub_id_3) {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (!sub_id_4) {
    sub_id_4 = localStorage.getItem("sub_id_4");
  }

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  useEffect(() => {
    const sendPixel = async () => {
      if (localStorage.getItem("pixelUsed")) {
        return;
      } else {
        // Inject Facebook pixel script
        const el = document.getElementById("pixelTagId");
        if (el) {
          /* global fbq */ // Declare fbq as a global variable
          // eslint-disable-next-line
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          );
          fbq("init", pixel);
          fbq("track", "Lead");
          el.innerHTML = `<img alt='test' src=https://www.facebook.com/tr?id=${pixel}&ev=PageView&noscript=1 style=\"display:none\" width=\"1px\" height=\"1px\">`;
        }
        localStorage.setItem("pixelUsed", true);
      }
    };

    sendPixel();
  }, []);
  useEffect(() => {
    localStorage.removeItem("isClickedInstall");
    setTimeout(() => {
      if (id === "1611") {
        window.location.href =
          `https://arumo.top/pwxJCtBF?a=b` +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1610") {
        window.location.href =
          `https://arumo.top/GqCYKmM3?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1613") {
        window.location.href =
          `https://arumo.top/1NgbpyMH?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1612") {
        window.location.href =
          `https://arumo.top/zcnm6vww?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1614") {
        window.location.href =
          `https://arumo.top/TLSGL1Gx?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else {
        window.location.href =
          `https://arumo.top/pwxJCtBF?a=b` +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      }
    }, 1100);
  }, []);

  return (
    <>
      <div class="loader"></div>
      <div id="pixelTagId"></div>
    </>
  );
};
